import { Badge } from "../components/ui/badge"
import { classes } from "../utils/util"
import { WORK } from "./work"
import { SERVICE_AREAS, TOWNS_BY_COUNTY } from "../data/service-areas"

export const COMPANY_EMAIL = "drdriphome@gmail.com"

export function Interlude({
  badgeCopy,
  title,
  description,
  className,
}: {
  badgeCopy: string
  title: string
  description: string
  className?: string
}) {
  return (
    <div className={classes("bg-sapphire-100 flex flex-col items-center justify-center px-4 text-sapphire-900", className)}>
      <Badge variant="secondary" className="mb-8">
        {badgeCopy}
      </Badge>

      <h1 className="text-5xl font-bold text-center mb-4">{title}</h1>

      <p className="text-xl text-center text-sapphire-700 mb-16 max-w-2xl">{description}</p>
    </div>
  )
}

function BusinessCredentials() {
  return (
    <div class="flex flex-col items-center justify-center my-2">
      <p className="text-sapphire-900 mb-4">Rivervale, NJ 07675</p>
      <div className="flex flex-col md:flex-row items-center gap-6 mb-4">
        <div className="flex flex-col items-center">
          <p className="text-sapphire-900">NJMP #: 36BI01371200</p>
          <p className="text-sapphire-900">NJHIC #: 13VH13069000</p>
          <p className="text-sapphire-900">Rockland County License #: P-24-281</p>
        </div>
        {/* <iframe border="0" frameborder="0" style={{ border: 0, height: 110, width: 150 }} src="https://seal-newjersey.bbb.org/frame/blue-seal-150-110-bbb-90235933.png?chk=569B3D3EFE"></iframe> */}
        {/* <iframe border="0" frameborder="0" style="border: 0; height:61px; width:293px;" src="https://seal-newjersey.bbb.org/frame/blue-seal-293-61-bbb-90235933.png?chk=569B3D3EFE"></iframe> */}
        {/* <iframe border="0" frameborder="0" style="border: 0; height:61px; width:293px;" src="https://seal-newjersey.bbb.org/frame/blue-seal-293-61-bbb-90235933.png?chk=569B3D3EFE"></iframe> */}
        <a 
          href="https://www.bbb.org/us/nj/westwood/profile/plumbing-and-heating/dr-drip-plumbing-and-heating-0221-90235933/#sealclick" 
          target="_blank" 
          rel="nofollow"
        >
          <img 
            src="https://seal-newjersey.bbb.org/seals/blue-seal-293-61-bbb-90235933.png" 
            style={{ border: 0 }}
            alt="Dr. Drip Plumbing and Heating BBB Business Review"
          />
        </a>
      </div>
      <div className="flex items-center space-x-4 mb-4">
        <a href="/privacy-policy" className="text-sapphire-900 hover:text-sapphire-700 transition-colors">
          Privacy Policy
        </a>
      </div>
      <footer className="text-sapphire-900">{`© Dr. Drip ${new Date().getUTCFullYear()}. All rights reserved.`}</footer>
    </div>
  )
}

export function Footer() {
  return (
    <footer className="bg-sapphire-900 text-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
          <div className="md:col-span-3 space-y-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">Contact</h3>
              <p>524 Alosio Dr</p>
              <p>River Vale, NJ 07675</p>
              <p><a href={`mailto:${COMPANY_EMAIL}`} className="hover:text-sapphire-300 transition-colors">{COMPANY_EMAIL}</a></p>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold mb-4">Hours</h3>
              <p>Monday - Friday: 8am - 6pm</p>
              <p>Saturday: 8am - 4pm</p>
              <p className="mt-2 text-sapphire-300">24/7 Emergency Service Available</p>
            </div>
          </div>

          <div className="md:col-span-3">
            <h3 className="text-lg font-semibold mb-4">Services</h3>
            <ul className="space-y-2">
              {WORK.map((work) => (
                <li key={work.title}>
                  <a 
                    href={`/services/${work.urlPath}`}
                    className="hover:text-sapphire-300 transition-colors"
                  >
                    {work.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="md:col-span-6">
            <h3 className="text-lg font-semibold mb-4">Service Areas</h3>
            <div className="grid grid-cols-2 gap-x-8">
              {Object.entries(TOWNS_BY_COUNTY).map(([county, towns]) => (
                <div key={county} className="mb-6">
                  <h4 className="text-sapphire-300 text-sm font-medium mb-2">{county}</h4>
                  <div className="grid grid-cols-2 gap-x-4 gap-y-1">
                    {towns.map(town => {
                      const area = SERVICE_AREAS.find(a => a.name === town);
                      return (
                        <div key={town}>
                          {area ? (
                            <a 
                              href={`/locations/${area.urlPath}`}
                              className="hover:text-sapphire-300 transition-colors text-sm"
                            >
                              {town}
                            </a>
                          ) : (
                            <span className="text-sm">{town}</span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="border sm:rounded-2xl border-sapphire-600 bg-sapphire-300 mt-8">
          <div className="flex items-center justify-center p-2">
            <a href="/" className="flex items-center space-x-3">
              <img
                class={`block h-24 mr-2`}
                src="/img/logo.png"
                alt="Dr. Drip"
              />
            </a>
          </div>
          <BusinessCredentials />
        </div>
      </div>
    </footer>
  )
}
