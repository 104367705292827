import type { Testimonial } from './local-testimonials';
import { getServiceContent } from './service-content';

export const SERVICE_TESTIMONIALS: Record<string, Testimonial[]> = {
  "Water softeners / filtration": [
    {
      quote: "The water softener Dr. Drip installed has made such a difference. Our dishes are spotless and our skin feels better after showering.",
      author: "Rachel M.",
      location: "River Vale"
    },
    {
      quote: "Great experience with their water filtration service. They explained all our options and installed the perfect system for our needs.",
      author: "David K.",
      location: "Westwood"
    }
  ],
  "Water heaters": [
    {
      quote: "Excellent tankless water heater installation. They helped us choose the right size and now we never run out of hot water.",
      author: "Michael P.",
      location: "Montvale"
    },
    {
      quote: "Our water heater died on a Sunday, and they came out immediately. Professional service and fair pricing.",
      author: "Sarah L.",
      location: "Hillsdale"
    }
  ],
  // Add more testimonials for each service...
};

export function getServiceTestimonials(serviceTitle: string): Testimonial[] {
  return SERVICE_TESTIMONIALS[serviceTitle] || [];
}

export { getServiceContent }; 