import {Route, Switch} from "wouter-preact"
import {Landing} from "../landing/landing.tsx"
import { LocationPage } from "../components/location-page.tsx";
import { ServicePage } from "../components/service-page.tsx"
import { PrivacyPolicy } from "../pages/privacy-policy.tsx"
import { SERVICE_AREAS } from "../data/service-areas"
import { WORK } from "../landing/work"

export function Routes() {
  return (
    <Switch>
      <Route path="/" component={Landing}/>
      <Route path="/privacy-policy" component={PrivacyPolicy}/>
      {SERVICE_AREAS.map(location => (
        <Route 
          key={location.urlPath}
          path={`/locations/${location.urlPath}`}
          component={() => <LocationPage location={location} />}
        />
      ))}
      {WORK.map(service => (
        <Route
          key={service.urlPath}
          path={`/services/${service.urlPath}`}
          component={() => <ServicePage service={service} />}
        />
      ))}
    </Switch>
  )
}
