import { h } from 'preact';

export function getServiceContent(serviceTitle: string): JSX.Element {
  const content: Record<string, JSX.Element> = {
    "Water softeners / filtration": (
      <div className="space-y-6 text-sapphire-800">
        <p className="text-lg leading-relaxed">
          Hard water can cause numerous issues in your home, from scale buildup in pipes to dry skin and lackluster laundry. Our water softening solutions are designed to address these problems effectively.
        </p>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Benefits of Water Softening:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Extends the life of your appliances</li>
            <li className="text-sapphire-700">Reduces scale buildup in pipes</li>
            <li className="text-sapphire-700">Softer skin and hair</li>
            <li className="text-sapphire-700">Cleaner dishes and brighter laundry</li>
            <li className="text-sapphire-700">Reduced soap and detergent usage</li>
          </ul>
        </div>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Our Water Treatment Solutions:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Whole-house water softening systems</li>
            <li className="text-sapphire-700">Reverse osmosis drinking water systems</li>
            <li className="text-sapphire-700">Water quality testing and analysis</li>
            <li className="text-sapphire-700">Filter replacement and maintenance</li>
            <li className="text-sapphire-700">Salt delivery service available</li>
          </ul>
        </div>
      </div>
    ),
    "Water pressure solutions": (
      <div className="space-y-6 text-sapphire-800">
        <p className="text-lg leading-relaxed">
          Proper water pressure is crucial for your home's plumbing system. Whether you're experiencing low pressure or dealing with excessive pressure, we have the expertise to help.
        </p>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Common Water Pressure Issues We Solve:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Low water pressure throughout the house</li>
            <li className="text-sapphire-700">Pressure drops during peak usage</li>
            <li className="text-sapphire-700">High pressure causing pipe stress</li>
            <li className="text-sapphire-700">Inconsistent pressure between floors</li>
            <li className="text-sapphire-700">Pressure issues affecting specific fixtures</li>
          </ul>
        </div>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Our Solutions Include:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Pressure reducing valve installation</li>
            <li className="text-sapphire-700">Whole-house pressure booster systems</li>
            <li className="text-sapphire-700">Pressure tank installation and repair</li>
            <li className="text-sapphire-700">Pipe sizing and optimization</li>
            <li className="text-sapphire-700">Regular pressure monitoring and maintenance</li>
          </ul>
        </div>
      </div>
    ),
    "Water heaters": (
      <div className="space-y-6 text-sapphire-800">
        <p className="text-lg leading-relaxed">
          Whether you're looking for a traditional tank water heater or a modern tankless system, we provide expert installation, repair, and maintenance services.
        </p>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Our Water Heater Services Include:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Tank and tankless water heater installation</li>
            <li className="text-sapphire-700">Emergency repairs and maintenance</li>
            <li className="text-sapphire-700">Energy efficiency upgrades</li>
            <li className="text-sapphire-700">Annual maintenance programs</li>
            <li className="text-sapphire-700">Water heater replacement consultation</li>
          </ul>
        </div>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Types of Water Heaters We Service:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Traditional tank water heaters</li>
            <li className="text-sapphire-700">Tankless water heaters</li>
            <li className="text-sapphire-700">Heat pump water heaters</li>
            <li className="text-sapphire-700">Hybrid water heaters</li>
            <li className="text-sapphire-700">Commercial water heating systems</li>
          </ul>
        </div>
      </div>
    ),
    "Faucets and fixtures": (
      <div className="space-y-6 text-sapphire-800">
        <p className="text-lg leading-relaxed">
          From simple faucet repairs to complete fixture installations, we ensure your home's plumbing fixtures work perfectly and look beautiful.
        </p>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Our Fixture Services:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Kitchen faucet installation and repair</li>
            <li className="text-sapphire-700">Bathroom fixture upgrades</li>
            <li className="text-sapphire-700">Shower and tub installations</li>
            <li className="text-sapphire-700">Commercial fixture services</li>
            <li className="text-sapphire-700">Designer fixture installation</li>
          </ul>
        </div>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">We Work With All Major Brands:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Moen, Delta, and Kohler</li>
            <li className="text-sapphire-700">American Standard</li>
            <li className="text-sapphire-700">Grohe and Hans Grohe</li>
            <li className="text-sapphire-700">Newport Brass</li>
            <li className="text-sapphire-700">And many more premium brands</li>
          </ul>
        </div>
      </div>
    ),
    "Frost free hose bibs": (
      <div className="space-y-6 text-sapphire-800">
        <p className="text-lg leading-relaxed">
          Protect your home from frozen pipes and water damage with our frost-free hose bib solutions. We specialize in modern, reliable outdoor plumbing fixtures.
        </p>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Benefits of Frost-Free Hose Bibs:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Prevents winter freeze damage</li>
            <li className="text-sapphire-700">Automatic drainage when turned off</li>
            <li className="text-sapphire-700">Longer lifespan than traditional spigots</li>
            <li className="text-sapphire-700">Improved safety and reliability</li>
            <li className="text-sapphire-700">Modern design options available</li>
          </ul>
        </div>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Our Hose Bib Services:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Frost-free hose bib installation</li>
            <li className="text-sapphire-700">Aquor frost-free systems</li>
            <li className="text-sapphire-700">Repair and maintenance</li>
            <li className="text-sapphire-700">Winterization services</li>
            <li className="text-sapphire-700">Upgrade consultations</li>
          </ul>
        </div>
      </div>
    ),
    "Toilets": (
      <div className="space-y-6 text-sapphire-800">
        <p className="text-lg leading-relaxed">
          From basic repairs to high-efficiency toilet installations, we handle all your toilet-related plumbing needs with expertise and professionalism.
        </p>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Our Toilet Services:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">New toilet installation</li>
            <li className="text-sapphire-700">Toilet repair and maintenance</li>
            <li className="text-sapphire-700">High-efficiency upgrades</li>
            <li className="text-sapphire-700">Commercial toilet services</li>
            <li className="text-sapphire-700">Emergency repairs</li>
          </ul>
        </div>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Types of Toilets We Install:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Standard flush toilets</li>
            <li className="text-sapphire-700">Dual-flush systems</li>
            <li className="text-sapphire-700">Comfort height toilets</li>
            <li className="text-sapphire-700">Water-saving models</li>
            <li className="text-sapphire-700">Smart toilets and bidets</li>
          </ul>
        </div>
      </div>
    ),
    "Hydronic and steam boilers": (
      <div className="space-y-6 text-sapphire-800">
        <p className="text-lg leading-relaxed">
          We specialize in all aspects of hydronic and steam heating systems, from routine maintenance to complete system installations.
        </p>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Our Boiler Services:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Boiler installation and replacement</li>
            <li className="text-sapphire-700">Emergency repair service</li>
            <li className="text-sapphire-700">Annual maintenance programs</li>
            <li className="text-sapphire-700">Energy efficiency upgrades</li>
            <li className="text-sapphire-700">Zone system installation</li>
          </ul>
        </div>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Types of Systems We Service:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Hot water boilers</li>
            <li className="text-sapphire-700">Steam boilers</li>
            <li className="text-sapphire-700">Radiant floor heating</li>
            <li className="text-sapphire-700">Multi-zone systems</li>
            <li className="text-sapphire-700">High-efficiency condensing boilers</li>
          </ul>
        </div>
      </div>
    ),
    "Find and repair leaks": (
      <div className="space-y-6 text-sapphire-800">
        <p className="text-lg leading-relaxed">
          Our advanced leak detection and repair services help protect your home from water damage and unnecessary water waste.
        </p>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Our Leak Detection Methods:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Electronic leak detection</li>
            <li className="text-sapphire-700">Thermal imaging</li>
            <li className="text-sapphire-700">Video pipe inspection</li>
            <li className="text-sapphire-700">Pressure testing</li>
            <li className="text-sapphire-700">Ultrasonic leak detection</li>
          </ul>
        </div>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Types of Leaks We Fix:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Slab leaks</li>
            <li className="text-sapphire-700">Hidden pipe leaks</li>
            <li className="text-sapphire-700">Fixture and faucet leaks</li>
            <li className="text-sapphire-700">Underground pipe leaks</li>
            <li className="text-sapphire-700">Emergency leak repairs</li>
          </ul>
        </div>
      </div>
    ),
    "Drain cleaning / hydro jetting": (
      <div className="space-y-6 text-sapphire-800">
        <p className="text-lg leading-relaxed">
          We use advanced techniques and equipment to clear even the most stubborn clogs and maintain your plumbing system's health.
        </p>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Our Drain Cleaning Services:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Hydro jetting</li>
            <li className="text-sapphire-700">Drain snaking</li>
            <li className="text-sapphire-700">Video pipe inspection</li>
            <li className="text-sapphire-700">Preventive maintenance</li>
            <li className="text-sapphire-700">Emergency clog removal</li>
          </ul>
        </div>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Types of Drains We Service:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Kitchen and bathroom drains</li>
            <li className="text-sapphire-700">Main sewer lines</li>
            <li className="text-sapphire-700">Storm drains</li>
            <li className="text-sapphire-700">Floor drains</li>
            <li className="text-sapphire-700">Commercial drain systems</li>
          </ul>
        </div>
      </div>
    ),
    "Frozen pipe thawing": (
      <div className="space-y-6 text-sapphire-800">
        <p className="text-lg leading-relaxed">
          When winter strikes and your pipes freeze, quick action is crucial to prevent costly damage. Our professional pipe thawing service uses specialized equipment to safely restore your water flow.
        </p>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Our Pipe Thawing Services:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Emergency pipe thawing</li>
            <li className="text-sapphire-700">Professional thawing machine technology</li>
            <li className="text-sapphire-700">Pipe inspection post-thawing</li>
            <li className="text-sapphire-700">Preventive winterization</li>
            <li className="text-sapphire-700">Pipe insulation services</li>
          </ul>
        </div>
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-sapphire-900">Benefits of Professional Pipe Thawing:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className="text-sapphire-700">Safe and efficient thawing process</li>
            <li className="text-sapphire-700">Prevents pipe bursts and damage</li>
            <li className="text-sapphire-700">Available 24/7 for emergencies</li>
            <li className="text-sapphire-700">Experienced technicians</li>
            <li className="text-sapphire-700">Long-term prevention strategies</li>
          </ul>
        </div>
      </div>
    )
  };

  return content[serviceTitle] || <p className="text-sapphire-800">{serviceTitle} content coming soon...</p>;
} 