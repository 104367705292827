import test from "node:test"
import { useEffect, useState } from "preact/compat"

// In over 30 years of dealing with home plumbing needs I found Dr Drip to be the most professional, knowledgeable and polite service that can be found in the Bergen County area. Thank you very much Nick and Christian

// The water to the house was not flowing. Thanks to Nick, and Christian, they evaluated the problem and discovered the problem was stemming from a clogged Pressure Relief Valve. Also, Nick, and Christian discovered a leak in the drain pipe of the sink. Thankfully, the water is flowing, and the leak has been resolved.
// Thank you Nick & Christian.
export const testimonials = [
  // {
  //   quote:
  //     "I don't do a lot of reviews but Nick and Christian are the absolute best. Our water heater broke yesterday evening and Nick was here within the hour. By 8am this morning Nick and Christian were already installing a new tank. They gave us a great price and even pitched in to help clean up the water mess from the old tank.I strongly recommend if you have a plumbing emergency call them first! Nick answers the phone himself - no answering service or voicemail..",
  //   author: "D T",
  //   avatar: "/img/testimonials/rachel.png",
  // source: "/img/google.png",
  // },
  {
    quote:
      "As a fellow commercial plumber, drain cleaning is not my specialty. Called up Nick from Dr.Drip and he had my kitchen sink unclogged in a timely fashion. Price was very reasonable and I’ll be sending Nick all my drain cleaning customers in the future. Great man and a veteran.",
    author: "Tyler Hering",
    avatar: "/img/testimonials/tyler_hering.png",
    source: "/img/google.png",
    alt: "Tyler Hering profile picture",
  },
  {
    quote:
      "The services I received from Dr Drip were excellent. Nick arrived on time and listened attentively as I explained what I needed. He completed the jobs and left my kitchen and bathroom work areas clean. I’ll definitely call Dr Drip for future plumbing needs.",
    author: "Vernice Alexander",
    avatar: "/img/testimonials/vernice.png",
    source: "/img/google.png",
    alt: "Vernice Alexander profile picture",
  },
  // {
  //   quote:
  //     "After chasing my local plumber around for over 3 months, I took matters into my own hands and searched for a plumber on Google.  Focusing on the highest reviews, I came across Drip Drain Plumbers who quickly answered the phone and currently had a plumber in my area! I was notified by text that Christian would arrive within the hour.  I had a couple of tricky plumbing issues that he managed to find a solution for and went to work.  I mentioned 2 other issues and he added them to the list as well.  Even though he arrived late in the afternoon, he was happy to stay until all of the work was completed,  well after 7pm.  His work was excellent, he was very professional, and cleaned up everything for the messy job that it was. Did I pay a little more than my local plumber, probably, but I would prefer the reliable professional service of Dr. Drip to saving a few bucks.  I signed up for the heating/AC maintenance plan as well, and I'm looking forward to that.  Thanks so much!",
  //   author: "Jean",
  //   avatar: "/img/testimonials/Jean.png",
  // source: "/img/google.png",
  // },
  {
    quote:
      "I reached out at 6am for a very clogged toilet and got a very quick response back. Nick came out early and was so kind, professional and quick. He was in and out within 30 minutes and honestly I cant recommend them enough! Reach out to them for all your needs!",
    author: "Satpal Singh",
    avatar: "/img/testimonials/satpal.png",
    source: "/img/google.png",
    alt: "Satpal Singh profile picture",
  },
  {
    quote:
      "Nick is very knowledge, diligent and trustworthy! We hired him for multiple services and he always gets the job done. We can’t recommend him enough.",
    author: "Angela Kung",
    avatar: "/img/testimonials/angela.png",
    source: "/img/google.png",
    alt: "Angela Kung profile picture",
  },
  {
    quote:
      "Nick from Dr. Drip Home Service really came through for us during our recent plumbing fiasco. He arrived quickly, bringing a friendly yet professional attitude that was much appreciated. With his expertise, Nick swiftly diagnosed the issue, clearly explained what needed to be done, and got to work. The problem was fixed efficiently, with Nick ensuring everything was clean and in order before he left. I wholeheartedly recommend Nick for any plumbing services; his skill and customer care are top-notch.",
    author: "Ari Bornstein",
    avatar: "/img/testimonials/ari.png",
    source: "/img/google.png",
    alt: "Ari Bornstein profile picture",
  },
  {
    quote:
      "Nick and Christian did a fantastic job replacing my boiler. They took the time to walk me through my options, ensuring I understood everything. Their attention to detail was impressive, and they maintained clear communication throughout the process. They were total professionals, and I appreciated how they left everything clean afterward. I'd absolutely recommend them to anyone needing their services.",
    author: "Audley Clarke",
    avatar: "/img/testimonials/audley.png",
    source: "/img/google.png",
    alt: "Audley Clarke profile picture",
  },
]

export function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % testimonials.length)
    }, 8000)

    return () => clearInterval(timer)
  }, [])

  const currentTestimonial = testimonials[currentIndex]

  return (
    <div className=" p-8 rounded-lg relative overflow-hidden pt-20" id="reviews">
      <div className="max-w-5xl mx-auto text-center relative z-10">
        <div className="flex justify-center mb-4">
          {[...Array(5)].map((_, i) => (
            <svg
              key={i}
              className="w-6 h-6 text-yellow-500"
              fill="currentColor"
              viewBox="0 0 20 20">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          ))}
        </div>

        <div className="mb-8">
          <div className="flex justify-center items-center space-x-1">
            <p className="font-bold text-sapphire-900">{currentTestimonial.author} </p>
            {/* <span>-</span> */}
            <img
              src={currentTestimonial.source ?? "/img/google.png"}
              className="h-20 object-cover"
              loading="lazy"
              alt="Google logo"
            />
          </div>

          <div className="flex justify-center space-x-2">
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className={`w-12 h-12 rounded-full border-2 ${
                  index === currentIndex ? "border-sapphire-600" : "border-transparent"
                } overflow-hidden`}
                onClick={() => setCurrentIndex(index)}>
                <img
                  src={testimonial.avatar}
                  alt={testimonial.alt}
                  className="w-full h-full object-cover"
                />
              </div>
            ))}
          </div>

          <div className="h-96 sm:h-56 mt-4 sm:mt-8 overflow-y-auto">
            <p className="text-2xl font-semibold text-sapphire-800 mb-6">
              {currentTestimonial.quote}
            </p>
          </div>
        </div>

        <div>
          <p>
            View all other {" "}
            <a
              class="underline text-sapphire-600"
              href="https://www.google.com/search?q=dr+drip+plumbing+nj&sca_esv=e17f8cc3d344f469&sca_upv=1&ei=9PruZo-uDbv50PEPxYPeiAM&ved=0ahUKEwiP-IPmwNSIAxW7PDQIHcWBFzEQ4dUDCA8&uact=5&oq=dr+drip+plumbing+nj&gs_lp=Egxnd3Mtd2l6LXNlcnAiE2RyIGRyaXAgcGx1bWJpbmcgbmoyBRAhGKABMgUQIRigATIFECEYoAEyBRAhGKABMgUQIRigATIFECEYqwJIzgdQvwJYqQVwAXgBkAEAmAF0oAG1AqoBAzIuMbgBA8gBAPgBAZgCBKACxALCAgoQABiwAxjWBBhHwgIGEAAYFhgewgILEAAYgAQYhgMYigXCAggQABiABBiiBJgDAIgGAZAGCJIHAzIuMqAHtBM&sclient=gws-wiz-serp#lrd=0x8091234c050b7779:0x4d30742dab741969,1,,,,">
              Google reviews here
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
