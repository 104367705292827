import { classes } from "../utils/util"
import { useId } from "preact/hooks"
import { Interlude } from "./util"

function ImageClipPaths({
    id,
    ...props
}: React.ComponentPropsWithoutRef<'svg'> & { id: string }) {
    return (
        <svg aria-hidden="true" width={0} height={0} {...props}>
            <defs>
                <clipPath id={`${id}-0`} clipPathUnits="objectBoundingBox">
                    <path d="M0,0 h0.729 v0.129 h0.121 l-0.016,0.032 C0.815,0.198,0.843,0.243,0.885,0.243 H1 v0.757 H0.271 v-0.086 l-0.121,0.057 v-0.214 c0,-0.032,-0.026,-0.057,-0.057,-0.057 H0 V0" />
                </clipPath>
                <clipPath id={`${id}-1`} clipPathUnits="objectBoundingBox">
                    <path d="M1,1 H0.271 v-0.129 H0.15 l0.016,-0.032 C0.185,0.802,0.157,0.757,0.115,0.757 H0 V0 h0.729 v0.086 l0.121,-0.057 v0.214 c0,0.032,0.026,0.057,0.057,0.057 h0.093 v0.7" />
                </clipPath>
                <clipPath id={`${id}-2`} clipPathUnits="objectBoundingBox">
                    <path d="M1,0 H0.271 v0.129 H0.15 l0.016,0.032 C0.185,0.198,0.157,0.243,0.115,0.243 H0 v0.757 h0.729 v-0.086 l0.121,0.057 v-0.214 c0,-0.032,0.026,-0.057,0.057,-0.057 h0.093 V0" />
                </clipPath>
            </defs>
        </svg>
    )
}

export type WorkItem = {
    imgSrc: string;
    title: string;
    description: string;
    urlPath?: string;
}

export const WORK: WorkItem[] = [
    {
        imgSrc: "/img/work/soft.jpeg",
        title: "Water softeners / filtration",
        description: "Hard water is known to cause more frequent issues in your home's plumbing system. It can also affect hair, skin, and clothes. We can provide a free test of your home's water and a custom solution to suit your home's water chemistry. Ask us about reverse osmosis drinking water filtration systems also!",
        urlPath: "water-softeners-filtration"
    },
    {
        imgSrc: "/img/work/green.jpg",
        title: "Water pressure solutions",
        description: "Does your home have high blood pressure? Faucets frequently dripping, or not getting enough pressure? We can handle that with whole house water pressure boosters / pressure reducing valves to ensure your home runs comfortably and smoothly.",
        urlPath: "water-pressure-solutions"
    },
    {
        imgSrc: "/img/work/navian.jpg",
        title: "Water heaters",
        //   We are experts in providing solutions for your homes hot water needs.
        description: "We specialize in high efficiency hot water systems, including condensing tankless water heaters, heat pump water heaters, indirect storage tanks, and a variety of designer hot water options to make sure you get the water you want, when you want it. ",
        urlPath: "water-heaters"
    },
    {
        imgSrc: "/img/work/faucet.jpg",
        title: "Faucets and fixtures",
        description: "We can install any type of faucet or fixture you can imagine.",
        urlPath: "faucets-and-fixtures"
    },
    {
        imgSrc: "/img/work/hydrant.jpg",
        title: "Frost free hose bibs",
        description: "Any type of hose bib or frost free hose bib you can imagine. Ask us about Aquor frost free hose bibs!",
        urlPath: "frost-free-hose-bibs"
    },
    {
        imgSrc: "/img/work/toilet.jpg",
        title: "Toilets",
        description: "Of course, we handle the essentials too! We can repair and replace toilets of all kinds.",
        urlPath: "toilets"
    },
    {
        imgSrc: "/img/work/heater.jpg",
        title: "Hydronic and steam boilers",
        description: "We specialize in troubleshooting your home’s heating equipment, and have what it takes to keep your system running. We also pride ourselves in our showroom quality HVAC installs, where we apply a meticulous level of attention to detail so that your heating system is something to brag about. Also be sure to ask us about high efficiency heating systems. ",
        urlPath: "hydronic-and-steam-boilers"
    },
    {
        imgSrc: "/img/work/leak.jpg",
        title: "Find and repair leaks",
        description: "Put your leaks in the past with our expert leak repair services. We have a vast range tools to use to find and repair ANY leak in your home, and specialize in finding and repairing the toughest slab leaks.",
        urlPath: "find-and-repair-leaks"
    },
    {
        imgSrc: "/img/christian2small.jpeg",
        title: "Drain cleaning / hydro jetting",
        description: "Full service drain cleaning, descaling and hydrojetting of drain lines. We can handle any clog, and have the tools to do it right so it doesn't come back.",
        urlPath: "drain-cleaning-hydro-jetting"
    },
    {
        imgSrc: "/img/work/frozen.png",
        title: "Frozen pipe thawing",
        description: "Don't let frozen pipes disrupt your life! Our specialized pipe thawing machine can safely and quickly thaw frozen pipes to prevent bursts and restore your water flow. Available for emergency service during cold snaps.",
        urlPath: "frozen-pipe-thawing"
    },
]

export function Work() {
    let id = useId()

    return (
        <div className="bg-sapphire-100 flex flex-col items-center justify-center px-4 py-24 text-sapphire-900" id="work">
            <ImageClipPaths id={id} />
            <Interlude
                badgeCopy="Work"
                title="What we do"
                description="We can fix any problem related to water or heat in your home. And we can often do it in multiple ways, so you can expect a few pricing options when working with us."
            />
            <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-y-16 md:grid-cols-3">
                {WORK.map((work, idx) => (
                    <a 
                        key={work.title}
                        href={`/services/${work.urlPath}`}
                        className="block group hover:no-underline"
                    >
                        <div class="h-64 w-64 relative transform overflow-hidden rounded-2xl">
                            <div
                                className={classes(
                                    'absolute bottom-6 left-0 right-4 top-0 rounded-4xl border transition duration-300 group-hover:scale-95 xl:right-6',
                                    [
                                        'border-blue-300',
                                        'border-indigo-300',
                                        'border-sky-300',
                                    ][1 % 3],
                                )}
                            />
                            <div
                                className="absolute inset-0 bg-indigo-50"
                                style={{ clipPath: `url(#${id}-${idx % 3})` }}
                            >
                                <img
                                    className="absolute inset-0 h-full w-full object-cover transition duration-300 group-hover:scale-110"
                                    src={work.imgSrc}
                                    alt={work.title}
                                    sizes="(min-width: 1280px) 17.5rem, (min-width: 1024px) 25vw, (min-width: 768px) 33vw, (min-width: 640px) 50vw, 100vw"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div class="w-64">
                            <h4 className="mt-8 font-display text-lg text-sapphire-900">
                                {work.title}
                            </h4>
                            <p className="mt-1 text-base text-sapphire-700">
                                {work.description}
                            </p>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    )
}