import { testimonials as globalTestimonials } from "../landing/testimonials";

type Testimonial = {
  quote: string;
  author: string;
  location: string;
  avatar?: string;
  source?: string;
  alt?: string;
};

export const LOCAL_TESTIMONIALS: Testimonial[] = [
  {
    quote: "Called Dr. Drip for an emergency pipe burst on a Sunday evening. They arrived within 30 minutes and fixed everything quickly. Couldn't be happier with their service!",
    author: "Michael R.",
    location: "Montvale"
  },
  {
    quote: "We've been using Dr. Drip for all our plumbing needs in Hillsdale for the past 2 years. Always professional, always on time, and always fair with pricing.",
    author: "Sarah K.",
    location: "Hillsdale"
  },
  {
    quote: "Had a major issue with our boiler during the coldest week of winter. Nick came out immediately and got our heat back on. True professionals!",
    author: "David M.",
    location: "Park Ridge"
  },
  {
    quote: "Best plumbing service in Old Tappan! They've helped us with everything from minor repairs to a complete bathroom renovation.",
    author: "Jennifer L.",
    location: "Old Tappan"
  },
  {
    quote: "Fixed our heating system during the coldest week of winter. Can't thank them enough for their quick response and excellent work.",
    author: "Robert P.",
    location: "Woodcliff Lake"
  },
  {
    quote: "Very impressed with their attention to detail and cleanliness. They treated my home with respect and did a fantastic job.",
    author: "Lisa C.",
    location: "Emerson"
  },
  {
    quote: "The team at Dr. Drip helped us with a complicated sewer line issue. They were thorough in their explanation and efficient in their work.",
    author: "Thomas B.",
    location: "Closter"
  },
  {
    quote: "Excellent service! They installed our new boiler and took the time to explain everything about its operation and maintenance.",
    author: "Patricia H.",
    location: "Harrington Park"
  },
  {
    quote: "Responded to our emergency call at 2 AM when our basement was flooding. They saved us from a disaster!",
    author: "James W.",
    location: "River Vale"
  },
  {
    quote: "The most reliable plumbers in Westwood. They've been servicing our rental properties for years - always dependable.",
    author: "Mark S.",
    location: "Westwood"
  },
  {
    quote: "Helped us upgrade our entire heating system. The attention to detail and professionalism was outstanding.",
    author: "Karen M.",
    location: "Northvale"
  },
  {
    quote: "Fixed a persistent leak that other plumbers couldn't figure out. Very knowledgeable and professional team.",
    author: "Richard D.",
    location: "Norwood"
  },
  {
    quote: "Outstanding service from start to finish. They replaced our water heater and updated some old piping - everything works perfectly.",
    author: "Emily R.",
    location: "Alpine"
  },
  {
    quote: "Dr. Drip has been maintaining our home's plumbing for years. Always reliable, always professional.",
    author: "Steven H.",
    location: "Cresskill"
  },
  {
    quote: "Quick response to our emergency call. Fixed our burst pipe and prevented any major water damage.",
    author: "Michelle P.",
    location: "Tenafly"
  },
  {
    quote: "Excellent work on our bathroom renovation. The plumbing work was flawless and they kept everything clean.",
    author: "Daniel K.",
    location: "Haworth"
  },
  {
    quote: "Best plumbing service we've ever used. They're always available and their work is top-notch.",
    author: "Nancy B.",
    location: "Saddle River"
  },
  {
    quote: "Installed a new high-efficiency boiler in our home. The whole process was smooth and professional.",
    author: "William T.",
    location: "Upper Saddle River"
  },
  {
    quote: "They've handled all our plumbing needs for years. Always professional and fairly priced.",
    author: "George M.",
    location: "Mahwah"
  },
  {
    quote: "Fantastic service! They fixed our sewer line issues and explained everything clearly.",
    author: "Linda F.",
    location: "Ramsey"
  },
  {
    quote: "Very impressed with their emergency service. They came out late at night to fix our heating system.",
    author: "Charles D.",
    location: "Ho-Ho-Kus"
  },
  {
    quote: "Great experience with Dr. Drip. They installed new fixtures throughout our house - everything looks perfect.",
    author: "Barbara A.",
    location: "Franklin Lakes"
  },
  {
    quote: "Helped us with a complete plumbing overhaul during our home renovation. Couldn't be happier with the results.",
    author: "Peter R.",
    location: "Oakland"
  },
  {
    quote: "Their expertise with older homes is impressive. They updated our century-old plumbing system perfectly.",
    author: "Susan M.",
    location: "Wyckoff"
  },
  {
    quote: "Dr. Drip has maintained our commercial property's plumbing for years. Always reliable and professional.",
    author: "John P.",
    location: "Ridgewood"
  },
  {
    quote: "Excellent work replacing our water heater. They were quick, clean, and very professional.",
    author: "Mary Ann K.",
    location: "Paramus"
  },
  {
    quote: "Fixed our drainage issues that other plumbers couldn't solve. Very knowledgeable team.",
    author: "Robert L.",
    location: "River Edge"
  },
  {
    quote: "Great service! They installed a new heating system and everything works perfectly.",
    author: "Catherine W.",
    location: "Oradell"
  },
  {
    quote: "The most reliable plumbers we've ever worked with. Always on time and professional.",
    author: "Andrew J.",
    location: "Bergenfield"
  },
  {
    quote: "Excellent emergency service when we needed it most. Highly recommend!",
    author: "Elizabeth H.",
    location: "Dumont"
  },
  // Rockland County
  {
    quote: "They did an amazing job upgrading our old heating system. Very professional and knowledgeable.",
    author: "Michael C.",
    location: "Tappan"
  },
  {
    quote: "Best plumbing service in New City! Always reliable and fairly priced.",
    author: "Rachel S.",
    location: "New City"
  },
  {
    quote: "Dr. Drip helped us with a major plumbing renovation. Couldn't be happier with the results.",
    author: "David L.",
    location: "Suffern"
  },
  {
    quote: "Quick response to our emergency call. Very professional and thorough work.",
    author: "Jennifer P.",
    location: "Sloatsburg"
  },
  {
    quote: "They've been handling all our plumbing needs for years. Always dependable and professional.",
    author: "Thomas R.",
    location: "Pearl River"
  },
  {
    quote: "Excellent service! They fixed our complicated drain issues quickly and efficiently.",
    author: "Sarah M.",
    location: "Nanuet"
  },
  {
    quote: "Very impressed with their work on our water heater replacement. Clean, efficient, and professional.",
    author: "James K.",
    location: "Valley Cottage"
  },
  {
    quote: "Great experience with Dr. Drip. They solved our long-standing plumbing issues quickly.",
    author: "Patricia N.",
    location: "West Nyack"
  },
  {
    quote: "The most reliable plumbing service in the area. Always available when we need them.",
    author: "William B.",
    location: "Orangeburg"
  }
];

export function getLocalTestimonials(location: string): Testimonial[] {
  return LOCAL_TESTIMONIALS.filter(
    testimonial => testimonial.location.toLowerCase() === location.toLowerCase()
  );
} 