import { useState, useEffect } from "preact/hooks"
import { Button } from "./ui/button"
import { Interlude, COMPANY_EMAIL } from "../landing/util"
import { PHONE_NUMBER } from "../landing/hero"
import emailjs from '@emailjs/browser'

interface FormData {
  name: string;
  email: string;
  phone: string;
  message: string;
}

// Replace these with your actual EmailJS credentials
const EMAILJS_SERVICE_ID = 'service_669lyxt'; 
const EMAILJS_TEMPLATE_ID = 'template_jpe7du8';
const EMAILJS_PUBLIC_KEY = 'vYdotnzT9EqrFcWn-'; // Get this from Account > API Keys in EmailJS dashboard

export function ContactForm() {
  const [formStatus, setFormStatus] = useState<'idle' | 'submitting' | 'success' | 'error'>('idle')
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    phone: '',
    message: ''
  })

  // Initialize EmailJS with your public key
  useEffect(() => {
    emailjs.init(EMAILJS_PUBLIC_KEY);
  }, []);

  const handleChange = (e: Event) => {
    const target = e.target as HTMLInputElement | HTMLTextAreaElement
    setFormData({
      ...formData,
      [target.id]: target.value
    })
  }
  
  const handleSubmit = async (e: Event) => {
    e.preventDefault()
    
    // Validate that either email or phone is provided
    if (!formData.email && !formData.phone) {
      alert('Please provide either an email address or phone number')
      return
    }

    setFormStatus('submitting')

    try {
      // Create template parameters
      const templateParams = {
        to_name: "Dr. Drip Plumbing",
        to_email: COMPANY_EMAIL,
        from_name: formData.name,
        from_email: formData.email || 'No email provided',
        phone_number: formData.phone || 'No phone provided',
        message: formData.message,
        reply_to: formData.email || COMPANY_EMAIL,
      }

      // Send email using EmailJS
      const response = await emailjs.send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID, 
        templateParams
      )

      if (response.status === 200) {
        setFormStatus('success')
        // Reset form
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: ''
        })
      } else {
        throw new Error('Failed to send email')
      }
      
      // Reset success status after 3 seconds
      setTimeout(() => {
        setFormStatus('idle')
      }, 3000)
      
    } catch (error) {
      console.error('Form submission error:', error)
      setFormStatus('error')
      
      // Reset error status after 3 seconds
      setTimeout(() => {
        setFormStatus('idle')
      }, 3000)
    }
  }

  return (
    <section id="contact" className="bg-sapphire-100 py-16">
      <div className="max-w-3xl mx-auto px-4">
        <Interlude
          badgeCopy="Contact"
          title="Get in Touch"
          description="Need help with a plumbing or heating issue? Fill out this form and we'll get back to you asap (usually we respond within an hour but sometimes in just a few minutes)."
          className="mb-8"
        />

        <form 
          className="space-y-6"
          onSubmit={handleSubmit}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="name" className="block font-medium text-sapphire-900 mb-1">
                Name *
              </label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-sapphire-200 rounded-lg focus:ring-2 focus:ring-sapphire-500 focus:border-transparent"
              />
            </div>

            <div>
              <label htmlFor="phone" className="block font-medium text-sapphire-900 mb-1">
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-sapphire-200 rounded-lg focus:ring-2 focus:ring-sapphire-500 focus:border-transparent"
              />
            </div>
          </div>

          <div>
            <label htmlFor="email" className="block font-medium text-sapphire-900 mb-1">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-sapphire-200 rounded-lg focus:ring-2 focus:ring-sapphire-500 focus:border-transparent"
            />
            <p className="mt-1 text-sm text-sapphire-600">
              * Either email or phone number is required
            </p>
          </div>

          <div>
            <label htmlFor="message" className="block font-medium text-sapphire-900 mb-1">
              How can we help? *
            </label>
            <textarea
              id="message"
              value={formData.message}
              onChange={handleChange}
              required
              rows={4}
              className="w-full px-4 py-2 border border-sapphire-200 rounded-lg focus:ring-2 focus:ring-sapphire-500 focus:border-transparent"
            />
          </div>

          <div className="flex flex-col items-center space-y-4">
            <div className="rounded-md shadow w-full sm:w-auto">
              <Button 
                type="submit"
                disabled={formStatus === 'submitting'}
                variant="primary"
                size="lg"
                className="w-full sm:w-auto"
              >
                {formStatus === 'submitting' ? 'Sending...' : 'Send Message'}
              </Button>
            </div>
            
            <div className="text-center">
              <p className="text-sapphire-700">or call us directly at</p>
              <a 
                href={`tel:${PHONE_NUMBER}`} 
                className="inline-block mt-1 text-sapphire-900 font-medium hover:text-sapphire-700 transition-colors"
              >
                {PHONE_NUMBER}
              </a>
            </div>
          </div>

          {formStatus === 'success' && (
          <div className="p-4 bg-green-100 text-green-700 rounded-lg text-center">
            Thank you! We'll be in touch soon.
          </div>
        )}

        {formStatus === 'error' && (
          <div className="p-4 bg-red-100 text-red-700 rounded-lg text-center">
            Something went wrong. Please try again or call us directly at {PHONE_NUMBER}.
          </div>
        )}
        </form>
      </div>
    </section>
  )
} 