import { Interlude, Footer } from "./util"
import { Testimonials } from "./testimonials"
import { Team } from "./team"
import { Header, Hero, PHONE_NUMBER } from "./hero"

import { Work } from "./work"
import { PhoneCallIcon } from "lucide-react"
import { ServiceArea } from "./service-area"
import { Helmet } from 'react-helmet'
import { ContactForm } from "../components/contact-form"

export const CALENDAR_LINK =
  "https://book.housecallpro.com/book/Dr-Drip-Plumbing-and-Heating-Services/6e5ae770d4fb403db314a3f51fe9484e?v2=true"

// <!--Start of Housecallpro Online booking button--> <button data-token="6e5ae770d4fb403db314a3f51fe9484e" data-orgname="Dr-Drip-Plumbing-and-Heating-Services" class="hcp-button" onClick="HCPWidget.openModal()"> Book online </button> <script async src="https://online-booking.housecallpro.com/script.js?token=6e5ae770d4fb403db314a3f51fe9484e&orgName=Dr-Drip-Plumbing-and-Heating-Services"> </script> <!--End of Housecallpro Online booking button-->

export function Landing() {
  const handleClick = (event: any, documentElementId: string) => {
    event.preventDefault()
    document
      ?.getElementById(documentElementId)
      ?.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  // Create structured data for local business
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "@id": "https://drdripplumbing.com",
    "name": "Dr. Drip Plumbing & Heating",
    "image": "/img/logo.png",
    "description": "Family-run and veteran-owned plumbing and heating company servicing Northern NJ and Rockland County, NY. We offer comprehensive plumbing and heating services including boiler installations, repairs, and maintenance.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "524 Alosio Dr",
      "addressLocality": "River Vale",
      "addressRegion": "NJ",
      "postalCode": "07675",
      "addressCountry": "US"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 40.99172893864309,
      "longitude": -74.01727342944773
    },
    "url": "https://drdripplumbing.com",
    "telephone": "+12017492034",
    "areaServed": [
      {
        "@type": "County",
        "name": "Bergen County"
      },
      {
        "@type": "County",
        "name": "Rockland County"
      }
    ],
    "priceRange": "$$",
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "opens": "08:00",
      "closes": "18:00"
    },
    "sameAs": [
      "https://www.facebook.com/drdripplumbing",
      "https://www.instagram.com/drdripplumbing"
    ]
  };

  return (
    <div className={"flex flex-col min-h-full w-full "}>
      <Helmet>
        <title>Dr. Drip Plumbing & Heating | Northern NJ & Rockland County Plumbers</title>
        <meta name="description" content="Family-run and veteran-owned plumbing and heating company servicing Northern NJ and Rockland County, NY. Available 24/7 for emergency services." />
        <meta name="keywords" content="plumber, heating services, emergency plumber, boiler repair, plumbing contractor, Bergen County plumber, Rockland County plumber, Dr. Drip Plumbing" />
        <meta property="og:title" content="Dr. Drip Plumbing & Heating | Expert Plumbing Services" />
        <meta property="og:description" content="Professional plumbing and heating services in Northern NJ and Rockland County. Family-run, veteran-owned, and available 24/7." />
        <meta property="og:image" content="/img/logo.png" />
        <meta property="og:url" content="https://drdripplumbing.com" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Dr. Drip Plumbing & Heating" />
        <meta name="twitter:description" content="Professional plumbing and heating services in Northern NJ and Rockland County. Family-run, veteran-owned, and available 24/7." />
        <meta name="twitter:image" content="/img/logo.png" />
        <link rel="canonical" href="https://drdripplumbing.com" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <div class="bg-sapphire-50 min-h-screen">
        <Header handleClick={handleClick} />
        <Hero handleClick={handleClick} imgSrc="/img/trucks/double_truck_sun.jpeg" />
        <Testimonials />
        <Work />
        
        <PartnerBrands />
        <Pricing />
        <Team />
        <ServiceArea />
        <ContactForm />
        <Footer />
      </div>
    </div>
  )
}

// New PartnerBrands component
function PartnerBrands() {
  const smallBrands = [
    { name: "Penguin", logo: "/img/partners/penguin.webp" },
    // { name: "Bradford White", logo: "/img/partners/bradford.svg" },
    { name: "Peerless Boilers", logo: "/img/partners/peerless.jpg" },
    { name: "Navien", logo: "/img/partners/navien.jpg" },
    { name: "Moen", logo: "/img/partners/moen.svg" },
  ];
  
  const largeBrands = [
    { name: "American Standard", logo: "/img/partners/as.svg" },
    { name: "US Boilers", logo: "/img/partners/usboiler.png" },
    { name: "Bradford White", logo: "/img/partners/bradford-white.png" },
  ];

  return (
    <div className="py-24 bg-white">
      <Interlude
        className="bg-white"
        badgeCopy="Our Partners"
        title="Meet Our Partner Brands"
        description="We proudly collaborate with the following brands to provide you with not only the best services, but also the best parts."
      />
      <div className="flex flex-wrap justify-center bg-white">
        {smallBrands.map((brand) => (
          <div key={brand.name} className="m-4">
            <img src={brand.logo} alt={brand.name} className={`h-32 ${brand.name === "Penguin" && 'mt-4'}`} />
          </div>
        ))}
      </div>
      <div className="flex flex-wrap justify-center bg-white">
        {largeBrands.map((brand) => (
          <div key={brand.name} className="m-12 sm:m-4">
            <img src={brand.logo} alt={brand.name} className="h-12" />
          </div>
        ))}
      </div>
    </div>
  );
}

// todo make the header use interlude
function Pricing() {
  // TODO: replace this with an ROI calculator that also shares the price?
  return (
    <div className="bg-sapphire-50">
    <div className="bg-sapphire-100 flex flex-col items-center justify-center px-4 py-24 text-sapphire-900">
      <Interlude
        badgeCopy="Membership"
        title="Join the family"
        description="Our membership guarantees same-day service and peace of mind for all your home service needs. Simple membership, flexible payment terms."
      />
                        <div className="rounded-md shadow">
                    <a
                      href={`tel:+1${PHONE_NUMBER}`}
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md btn btn-primary md:py-4 md:text-lg md:px-10">
                      <PhoneCallIcon className="mr-2" />
                      Call {PHONE_NUMBER}
                    </a>
                  </div>
      {/* <a href={"todo"} target="_blank" className="btn btn-primary p-4 mb-12 -mt-8">
        Join now
      </a> */}
      <div className="grid grid-cols-1 gap-8 w-full max-w-5xl">
        {[
          // {
          //   title: "Starter",
          //   subtitle: "Brands < $5M",
          //   description:
          //     "Start fighting deductions as far back as a year with the platform and service starting at $1750/month with 10% repayment.",
          // },
          // {
          //   title: "Scaler",
          //   subtitle: "Brands < $50M",
          //   description:
          //     "Automate deductions and your accounting workflows, with monthly fees that scale to $5k/month based on volume.",
          // },
          // {
          //   title: "Custom",
          //   subtitle: "Enterprise",
          //   description:
          //     "For larger brands that need enterprise features or a custom solution, we'd love to chat about your use cases so we can build an ROI story together.",
          // },
        ].map((stat, index) => (
          <div key={index} className="bg-sapphire-100 p-4 rounded-lg text-center">
            <h2 className="text-4xl font-bold mb-2">{stat.title}</h2>
            <h3 className="text-xl font-semibold mb-4">{stat.subtitle}</h3>
            <p className="text-sapphire-700">{stat.description}</p>
          </div>
        ))}
      </div>
    </div>
    </div>
  )
}
