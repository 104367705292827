import { useEffect, useRef } from 'preact/hooks';
import { Footer, Interlude } from '../landing/util';
import { SimpleHeader } from '../landing/hero';
import { WORK } from '../landing/work';
import { Helmet } from 'react-helmet';
import { getLocalTestimonials } from "../data/local-testimonials";
import { ContactForm } from '../components/contact-form';

type LocationDetails = {
  name: string;
  county: string;
  description: string;
  center: { lat: number; lng: number };
  population?: string;
  yearFounded?: string;
  keyLandmarks?: string[];
  serviceHighlights?: string[];
}

export function LocationPage({ location }: { location: LocationDetails }) {
  const mapRef = useRef<HTMLDivElement>(null);

  // Create structured data for local business
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "@id": `https://drdripplumbing.com/locations/${location.name.toLowerCase().replace(/\s+/g, '-')}`,
    "name": "Dr. Drip Plumbing & Heating",
    "image": "/img/logo.png",
    "description": location.description,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "524 Alosio Dr",
      "addressLocality": "River Vale",
      "addressRegion": "NJ",
      "postalCode": "07675",
      "addressCountry": "US"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": location.center.lat,
      "longitude": location.center.lng
    },
    "url": `https://drdripplumbing.com/locations/${location.name.toLowerCase().replace(/\s+/g, '-')}`,
    "telephone": "+12017492034",
    "areaServed": {
      "@type": "City",
      "name": location.name,
      "containedIn": location.county
    },
    "priceRange": "$$",
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "opens": "08:00",
      "closes": "18:00"
    },
    "sameAs": [
      "https://www.facebook.com/drdripplumbing",
      "https://www.instagram.com/drdripplumbing"
    ]
  };

  const localTestimonials = getLocalTestimonials(location.name);

  useEffect(() => {
    if (!mapRef.current) return;

    import('@googlemaps/js-api-loader').then(({ Loader }) => {
      const loader = new Loader({
        apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
        version: "weekly",
      });

      loader.load().then(() => {
        const map = new google.maps.Map(mapRef.current!, {
          zoom: 14,
          center: location.center,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        });

        // Add marker for the town center
        new google.maps.Marker({
          map,
          position: location.center,
          title: location.name,
          animation: google.maps.Animation.DROP
        });
      });
    });
  }, [location]);

  return (
    <div className="min-h-screen bg-sapphire-50 flex flex-col">
      <Helmet>
        <title>Dr. Drip Plumbing & Heating Services in {location.name}, {location.county}</title>
        <meta name="description" content={`Professional plumbing and heating services in ${location.name}. ${location.description} Available 24/7 for emergency services.`} />
        <meta name="keywords" content={`plumber ${location.name}, heating services ${location.name}, emergency plumber ${location.name}, boiler repair ${location.name}, plumbing contractor ${location.county}, Dr. Drip Plumbing`} />
        <meta property="og:title" content={`Dr. Drip Plumbing & Heating - ${location.name} Services`} />
        <meta property="og:description" content={location.description} />
        <meta property="og:image" content="/img/logo.png" />
        <meta property="og:url" content={`/locations/${location.name.toLowerCase().replace(/\s+/g, '-')}`} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={`Dr. Drip Plumbing & Heating - ${location.name} Services`} />
        <meta name="twitter:description" content={location.description} />
        <meta name="twitter:image" content="/img/logo.png" />
        <link rel="canonical" href={`/locations/${location.name.toLowerCase().replace(/\s+/g, '-')}`} />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <SimpleHeader />
      <main className="flex-grow">
        <div className="pt-12 bg-sapphire-100">
          <Interlude
            badgeCopy={location.county}
            title={location.name}
            description={location.description}
          />
        </div>
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Services Section */}
          <div className="mb-12 text-center">
            <h3 className="text-2xl text-sapphire-900 font-semibold mb-6">Our Services in {location.name}</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 max-w-4xl mx-auto">
              {WORK.map(service => (
                <a 
                  key={service.title}
                  href={`/services/${service.urlPath}`}
                  className="bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow text-sapphire-900"
                >
                  {service.title}
                </a>
              ))}
            </div>
          </div>

          {/* Testimonials Section */}
          {localTestimonials.length > 0 && (
            <div className="mb-12 max-w-4xl mx-auto">
              <h3 className="text-2xl text-sapphire-900 font-semibold mb-8 text-center">
                What Our {location.name} Customers Say
              </h3>
              <div className="flex justify-center">
                <div 
                  className="bg-white p-8 rounded-lg shadow-sm max-w-2xl w-full"
                >
                  <blockquote className="text-sapphire-800 text-lg">
                    "{localTestimonials[0].quote}"
                  </blockquote>
                  <div className="mt-6 flex items-center">
                    {localTestimonials[0].avatar && (
                      <img 
                        src={localTestimonials[0].avatar}
                        alt={localTestimonials[0].alt || `${localTestimonials[0].author}'s profile`}
                        className="w-12 h-12 rounded-full mr-4"
                      />
                    )}
                    <div>
                      <div className="font-semibold text-sapphire-900">{localTestimonials[0].author}</div>
                      {localTestimonials[0].source && (
                        <img 
                          src={localTestimonials[0].source}
                          alt="Review source"
                          className="h-5 mt-1"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Map Section */}
          <div className="w-full max-w-4xl mx-auto mb-16">
            <div 
              ref={mapRef} 
              className="w-full h-[400px] rounded-lg shadow-lg"
            />
          </div>

          {/* Contact Form Section */}
          <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-sm overflow-hidden">
            <div className="bg-sapphire-900 text-white py-8 px-6 text-center">
              <h2 className="text-2xl font-semibold mb-2">
                Need Plumbing Services in {location.name}?
              </h2>
              <p className="text-sapphire-100">
                Contact us today for a consultation or emergency service
              </p>
            </div>
            <ContactForm />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
} 